.nav{
  position: fixed;
  height:10vh;
  width:100%;
  top:0;
  left:0;
  right:0;
  background: rgba(21,22,22, 0.5);
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &-toggle {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  &-links {
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    a{
      font-family: 'Cinetype Bold';
      font-size: 1.2em;
      color:white;
      text-decoration: none;
  
    }
    a:hover{
      color: rgba(256, 256, 256, 0.7);
    }
  }
}

@media screen and (max-width: 768px){
  .nav{
    height: 5vh;
    &-toggle {
      display: block;
      color: white;
    }
  
    &-links {
      display: none;
      flex-direction: column;
      gap: 10px;
      a{
        font-size: 1.5em;
      }
    }
  
    &-links.open {
      display: flex;
      margin: 0 auto;
      text-align: center;
      width: 100%;
      height: 95vh;
      position: absolute;
      background: rgba(21,22,22);
      z-index: 1;
      top: 5vh;
      left: 0;
      visibility: visible;
      opacity: 1;
      transition: opacity 2s linear;
    }
   
  }
}