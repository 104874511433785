.intro{
  width: 100%;
  padding-top: 10vh;
  height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: 'Cinetype Light';

  .gradient{
    position: absolute;
    height:20vh;
    width:100%;
    top:0;
    left:0;
    right:0;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 76%);

  }
  &-top{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    margin:0 auto;

    .left{
      height: 50%;
      width:30%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:0 auto;
      
      img{
        width: 100%;
        height: 80%;
        border-radius: 10%;  /* Rounded border */
        padding: 5px; /* Some padding */
      }
    }

    .right{
      height: 50%;
      width: 80%;
      padding-left: 5%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 0 auto;

      h2{
        font-family: 'Cinetype Light';
        #name{
          font-family: 'Cinetype Bold';
        }
        color: white;
        font-size: 2.3em;
        
        #period{
          color:$secondary-color;
        }
      }
      h2,h3,h4{
        margin-bottom:2%;
      }

      h3{
        color: white;
        font-size: 1.4em;
      }

      .box{
        background-color:$secondary-color;
        height: 10px;
        width: 20%;
        margin: 1% 0 2% 0;
      }

      h4{
        color: white;
        font-family: 'Cinetype Light';
        font-size: 1.25em;
      }
    }
    
  }
  

  &-bottom{
    height: 30%;
    width: 80%;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    .box{
      // background-color: rgba(0,0,0,0.8);
      padding: 1%;
      position: absolute;
      bottom:5%;
      border-radius: 8px;
      text-align: center;
    }
    h4{
      color: white;
      font-size: 1.5em;
      font-family: 'Ubuntu', sans-serif;
      animation-name: copy;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
    }
    
  }

  @keyframes copy {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
}

@media only screen and (min-width:320px) and (max-width:780px) {
  .intro{
    &-top{
      width: 90%;
      height: 100%;
      .left{
        width: 70%;
        height: 38%;
        h2{
          font-size: 10vw;
          text-align: center;
        }
        h3{
          font-size: 7vw;
          text-align: center;
        }
      }
      .right{
        padding-left: 0;
        justify-content: space-around;  
        width: 90%; 
        height: 60%;     
        img{
          width: 70%;
          height: 40%;
        }
        .box{
          height: 10px;
        }
        h2{
          font-size: 1.5em;
        }
        h4{
          font-size: 0.8em;
        }
        h3{
          font-size: 1em;
        }
      }
    }
    &-bottom{
      h4{
        font-size: 5vw;
      }
    }
  }
}

.linkedin-button {
  font-family: 'Cinetype Bold';
  background-color: $secondary-color; // LinkedIn brand color
  color: white; // Text color for contrast
  padding: 10px 10px; // Padding around the text
  border-radius: 5px; // Rounded corners for the button
  text-decoration: none; // Remove underline from the link
  font-size: 0.8em; // Font size
  font-weight: bold; // Make text bold
  text-align: center; // Center align the text
  display: inline-block; // Display as inline-block to apply padding and margin
  transition: background-color 0.3s, transform 0.3s; // Smooth transition for hover effects

  &:hover {
    background-color: #005a86; // Darken the button on hover for a subtle effect
    transform: translateY(-2px); // Slightly raise the button on hover
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); // Add shadow for 3D effect on hover
  }

  &:active {
    transform: translateY(1px); // Push the button down on click
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); // Less shadow to simulate the button being pressed
  }
}
