.projects {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  
  &-container {
    width: 70%;
    height: 100%;
    margin: 0 auto;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
    .info {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @include header;
      
      .list {
        height: max-content;
        width: 100%;
        
        .item {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          
          &-bullet {
            display: flex;
            flex-direction: column;
            width: 20%;
            align-items: center;
            
            .line {
              width: 2px;
              height: 100%;
              background: linear-gradient(180deg, rgba(9,9,9,1) 0%, rgba(255,255,255,1) 54%);
            }
          }
          
          &-info {
            padding: 5% 0;
            width: 80%;
            h3{
              a{
                  color: white;
              }
            }
            h2, h3, li {
              color: white;
              font-family: 'Cinetype Regular';
              margin-bottom: 2%;
            }
            h2 {
              font-size: 2em;
              font-family: 'Cinetype Bold';
            }
            li {
              list-style: none;
              padding-bottom: 1%;
              font-size: 1.5em;
            }
            h3:nth-of-type(2) {
              font-family: 'Cinetype Bold';
            }
          }
        }
        
        .primary {
          padding-top: 15vh;
          width: 100%;
        }
      }
    }
  }
  .hor {
    width: 100%;
    height: 2px;
    background: radial-gradient(circle, rgba(255,255,255,1) 52%, rgba(9,9,9,1) 95%);
  }
}

@media only screen and (min-width:320px) and (max-width:780px) {
  .projects{
    &-container{
      width: 90%;
      .info{
        .list{
          .item{
            &-info{
              li{
                font-size: 0.8em;
              }
            }
          }
        }
      }
    }
  }
}